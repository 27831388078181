import React, { useRef, useState } from "react";
import * as Unicons from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";

export default function GetInTouch() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isSending, setIsSending] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const form = useRef();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    // const token = window.grecaptcha.getResponse();
    // const _token = window.grecaptcha.ready(function () {
    //   window.grecaptcha
    //     .execute("reCAPTCHA_site_key", { action: "submit" })
    //     .then(function (token) {
    //       // Add your logic to submit to your backend server here.
    //       return token;
    //     });
    // });
    // if (!_token) {
    //   setFeedbackMessage("Please complete the reCAPTCHA.");
    //   return;
    // }


    setIsSending(true);

    // Replace these values with your EmailJS User ID, Service ID, and Template ID
    const userId = "v_IGZzUxEYAi6Bk1_";
    const serviceId = "service_bfwbsxu";
    const templateId = "template_mhw9d9r";

    const templateParams = {
      name: formData.name,
      email: formData.email,
      subject: formData.subject,
      message: formData.message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Email sent successfully!")
          setFeedbackMessage("Email sent successfully!");
          setFormData({ name: "", email: "", message: "", subject: "" });
          
        },
        (error) => {
          console.log("FAILED...", error);
          setFeedbackMessage("Failed to send email.");
        }
      )
      .finally(() => {
        setIsSending(false);
      });
  };

  return (
    <section
      className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
      id="contact"
    >
      <div className="container">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">
            Get In Touch !
          </h3>

          <p className="text-slate-400 max-w-xl mx-auto text-[15px]">
            Lets Connect and Elevate Your Business
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-[30px]">
          <div className="lg:col-span-8">
            <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
              <form ref={form} onSubmit={handleSubmit}>
                <div className="grid lg:grid-cols-12 lg:gap-5">
                  <div className="lg:col-span-6 mb-5">
                    <input
                      name="name"
                      id="name"
                      type="text"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]"
                      placeholder="Name :"
                    />
                  </div>

                  <div className="lg:col-span-6 mb-5">
                    <input
                      name="email"
                      id="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]"
                      placeholder="Email :"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1">
                  <div className="mb-5">
                    <input
                      name="subject"
                      id="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                      className="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]"
                      placeholder="Subject :"
                    />
                  </div>

                  <div className="mb-5">
                    <textarea
                      id="comments"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      className="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-28 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]"
                      placeholder="Message :"
                    ></textarea>
                  </div>
                </div>
                {/* <div className="g-recaptcha mb-2"  data-sitekey="6LfCnBQqAAAAAD6UI9KK917cCQe_nybecWMh6bHp"></div> */}

                <button
                  type="submit"
                  id="submit"
                  className="  btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white rounded-md h-11 justify-center flex items-center"
                >
                 {isSending ? "Sending..." : "Send Message"}
                </button>
              </form>
            </div>
          </div>

          <div className="lg:col-span-4">
            <div className="lg:ms-8">
              <div className="flex">
                <div className="icons text-center mx-auto">
                  <Unicons.UilPhone className=" block rounded text-2xl dark:text-white mb-0" />
                </div>

                <div className="flex-1 ms-6">
                  <h5 className="text-[17px] dark:text-white mb-2 font-medium">
                    Phone & WhatsApp
                  </h5>
                  <Link
                    to="tel:+923011638559"
                    className="text-slate-400 text-[15px]"
                  >
                    +92 3011638559
                  </Link>
                </div>
              </div>

              <div className="flex mt-4">
                <div className="icons text-center mx-auto">
                  <Unicons.UilEnvelope className=" block rounded text-2xl dark:text-white mb-0" />
                </div>

                <div className="flex-1 ms-6">
                  <h5 className="text-[17px] dark:text-white mb-2 font-medium">
                    Email
                  </h5>
                  <Link
                    to="mailto:contact@example.com"
                    className="text-slate-400 text-[15px]"
                  >
                    info@virtualabsolutions.com
                  </Link>
                </div>
              </div>

              <div className="flex mt-4">
                <div className="icons text-center mx-auto">
                  <Unicons.UilMapMarker className=" block rounded text-2xl dark:text-white mb-0" />
                </div>

                <div className="flex-1 ms-6">
                  <h5 className="text-[17px] dark:text-white mb-2 font-medium">
                    Location
                  </h5>
                  <p className="text-slate-400 text-[15px] mb-2">
                    Hyderabad Pakistan
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
