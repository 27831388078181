import * as Icon from "react-feather";

export const expertiseData = [
  {
    title: "Meta ads management",
    Icon: Icon.Monitor,
  },
  {
    title: "Google ads management",
    Icon: Icon.StopCircle,
  },
  {
    title: "Campaign optimization ",
    Icon: Icon.Video,
  },
  {
    title: "Budget planning",
    Icon: Icon.Coffee,
  },
  {
    title: "Marketing strategies",
    Icon: Icon.Music,
  },
  {
    title: "A/B testing and optimization",
    Icon: Icon.Watch,
  },
  {
    title: "Social media management",
    Icon: Icon.Box,
  },
  {
    title: "Content creation",
    Icon: Icon.Target,
  },
  {
    title: "Competitor analysis",
    Icon: Icon.PenTool,
  },
  {
    title: "Copy writing",
    Icon: Icon.Book,
  },
  {
    title: "Creative Designing ",
    Icon: Icon.Smartphone,
  },
  {
    title: "Photography ",
    Icon: Icon.Activity,
  },
  {
    title: "Business Consultation",
    Icon: Icon.Activity,
  },
  {
    title: "Project Outsourcing",
    Icon: Icon.Power,
  },
];
export const servicesData = [
  {
    title: "Bookkeeping",
desc: "Maintain accurate and up-to-date financial records with our comprehensive bookkeeping services. We manage all your daily transactions, ensuring every detail is meticulously recorded so you can focus on growing your business.",
    Icon: Icon.Briefcase,
  },
  {
    title: "Accounting",
    desc: "Our professional accounting services provide a solid financial foundation for your business. From handling accounts payable and receivable to preparing financial statements, we ensure your finances are in perfect order and fully compliant",
    Icon: Icon.Briefcase,
  },
  {
    title: "Financial Reporting",
    desc: "Gain valuable insights into your financial performance with our detailed financial reporting. We produce customized reports that highlight key metrics, empowering you to make informed decisions and drive business growth",
    Icon: Icon.Briefcase,
  },
  {
    title: "Financial Consulting",
    desc: "Overcome financial challenges with our expert financial consulting services. We offer strategic advice on budgeting, forecasting, and financial planning to help you achieve your business objectives and ensure long-term success",
    Icon: Icon.Briefcase,
  },
  {
    title: "Software Support",
    desc: "Enhance your accounting processes with our software support services. We assist in selecting, setting up, and maintaining the right accounting software for your business, ensuring smooth operations and increased efficiency",
    Icon: Icon.Briefcase,
  },
  {
    title: "Payroll Processing",
    desc: "Ensure your employees are paid accurately and on time with our comprehensive payroll processing services. We manage payroll calculations, handle tax deductions, and ensure compliance with all legal requirements, allowing you to focus on running your business without the stress of payroll management",
    Icon: Icon.Briefcase,
  },
];
export const project = [
  {
    image: "/images/works/1.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/2.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/3.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/4.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/5.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/6.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/7.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/8.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
];
export const blogData = [
  {
    image: "/images/blog/01_giglink.jpg",
    title: "Giglink: Tailwind CSS NFT Marketplace Template",
    desc: "",
  },
  {
    image: "/images/blog/01_techwind.jpg",
    title: "Techwind: Tailwind CSS Multipurpose Template",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    image: "/images/blog/01_upwind.jpg",
    title: "Upwind: Tailwind CSS Landing Page Template",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
];
export const review = [
  
  {
    name: "Ayesha Khan",
    designation: "CEO of Khan Enterprises",
    image: require('../assets/client/155.png'),
    desc: "VABS has transformed our financial management. Their attention to detail and accuracy is unmatched. We feel more confident in our business decisions thanks to their expert services",
  },
  {
    name: "Bilal Ahmed",
    designation: "Owner of Bilal's Café",
    image: require('../assets/client/144.png'),
    desc: "The team at VABS is fantastic! Their bookkeeping services are thorough and reliable, saving us time and reducing our stress. Highly recommend their services to any small business owner.",
  },
  {
    name: "Maria Malik",
    designation: "Founder of Malik Fashion House",
    image: require('../assets/client/155.png'),
    desc: "Since partnering with VABS, we've seen a significant improvement in our financial organization. Their personalized approach and dedication to our business needs have been invaluable.",
  },
  {
    name: "Usman Sheikh",
    designation: "Managing Director of Tech Innovators",
    image: require('../assets/client/144.png'),
    desc: "VABS has provided us with excellent financial advice and support. Their expertise in accounting and bookkeeping has helped streamline our operations and improve our bottom line.",
  },
  {
    name: "Sara Farooq",
    designation: "General Manager of Farooq Construction",
    image: require('../assets/client/155.png'),
    desc: "Working with VABS has been a game-changer for our business. Their comprehensive financial services have given us the insights we need to grow and succeed. Highly professional and reliable team",
  },
];
