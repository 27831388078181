import React from "react";
import { Link } from "react-router-dom";
export default function AddBanner() {
  return (
    <section className="py-20 w-full table relative  bg-no-repeat bg-center bg-cover">
      <div className="absolute inset-0 bg-slate-900/70"></div>
      <div className="container relative">
        <div className="grid grid-cols-1 text-center">
          <h3 className="mb-4 md:text-2xl text-xl text-white font-semibold">
            Transform Your Finances with Expert Care - Hire Us Today!
          </h3>

          <p className="text-white/80 max-w-xl mx-auto text-[15px]">
            At VABS, we offer tailored accounting and bookkeeping solutions
            designed to meet the unique needs of your business. Our experienced
            team is committed to providing accurate, reliable, and
            cost-effective financial services. Partner with us and experience
            the peace of mind that comes from knowing your finances are in
            expert hands.
          </p>

          <div className="relative mt-8">
            <a
              target="blank"
            href="https://wa.me/+923011638559"
              className="btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white rounded-md"
            >
              Chat With Us
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
